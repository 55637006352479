import React from "react"
import Layout from "./layout"
import SEO from "./seo"
import { Link } from "gatsby"
import Section, * as SectionElement from "./section"
import Logo from "./Logo/Logo"
import { Box } from "@theme-ui/components"

import PropTypes from "prop-types"

const MDXPage = ({ children, pageContext }) => {
  const title = pageContext?.frontmatter?.title ?? ""

  return (
    <Layout>
      <SEO title={title} />
      <Section
        py={0}
        contentSx={{
          pt: 10,
        }}
      >
        <Box pb={15}>
          <Link to="/">
            <Logo
              sx={{
                maxHeight: "3rem",
              }}
            />
          </Link>{" "}
        </Box>
        <SectionElement.Header
          title={title.toUpperCase()}
          titleTextColor="white"
        />
      </Section>
      <Section
        backgroundColor="white"
        contentSx={{
          alignItems: "flex-start",
        }}
      >
        {children}
      </Section>
    </Layout>
  )
}

MDXPage.propTypes = {
  children: PropTypes.node.isRequired,
}
export default MDXPage
