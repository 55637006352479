import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/believethehype/danielmazzola.de/src/components/default-page-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anbieter`}</h2>
    <p>{`Daniel Mazzola`}<br parentName="p"></br>{`
`}{`Forster Str. 5`}<br parentName="p"></br>{`
`}{`10999 Berlin`}<br parentName="p"></br>{`
`}{`Deutschland  `}</p>
    <p>{`Telefonnummer:`}<br parentName="p"></br>{`
`}{`030 33 00 77 55`}</p>
    <p>{`Mail:`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "mailto:info@danielmazzola.de"
      }}>{`info@danielmazzola.de`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      